import { FaAt, FaFacebookF, FaFacebookMessenger, FaPhone, FaWhatsapp } from 'react-icons/fa'
import { CELLPHONE, EMAIL, FACEBOOK_PAGE, MESSENGER, WHATSAPP } from '../Config'
import { Sidebar, Menu, MenuItem, useProSidebar } from 'react-pro-sidebar'

type IPage = {
    title: string
    url: string
}

const PAGES: IPage[] = [
    { title: 'בית', url: '#home' },
    { title: 'שירותים', url: '#services' },
    { title: 'למה לבחור בנו', url: '#why' },
    { title: 'מה אומרים עלינו', url: '#work' },
    // { title: 'צור קשר', url: '#contact' },
]

export function Nav() {
    const { collapseSidebar, collapsed } = useProSidebar()

    return (
        <>
            <nav className="flex items-center justify-between py-4 navbar navbar-expand-lg">
                <a
                    className="navbar-brand mr-5"
                    href="#home"
                    style={{
                        fontSize: '3rem',
                        fontWeight: 'bold',
                        color: '#000',
                    }}
                >
                    <span style={{ color: '#001e5b' }}>VISA</span>
                    <span style={{ fontSize: '3.7rem' }}>4</span>
                    <span
                        style={{
                            color: '#d00000',
                            fontWeight: 'bold',
                        }}
                    >
                        USA
                    </span>
                </a>
                <button
                    className="block navbar-toggler focus:outline-none lg:hidden ml-5"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarOne"
                    aria-controls="navbarOne"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={() => collapseSidebar()}
                >
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                </button>

                <DesktopNav />
            </nav>
            <MobileSidebar collapsed={collapsed} collapseSidebar={collapseSidebar} />
        </>
    )
}

function DesktopNav() {
    return (
        <div
            className="absolute left-0 z-20 hidden w-full px-5 py-3 duration-300 bg-white lg:w-auto collapse navbar-collapse lg:block top-full mt-full lg:static lg:bg-transparent shadow lg:shadow-none"
            id="navbarOne"
        >
            <ul id="nav" className="items-center content-start mr-auto lg:justify-end navbar-nav lg:flex">
                {PAGES.map((page, index) => (
                    <li key={index} className="nav-item mr-5 lg:ml-11">
                        <a className="page-scroll" href={page.url}>
                            {page.title}
                        </a>
                    </li>
                ))}
                <li className="nav-item mr-5 lg:ml-11">
                    <a className="page-scroll" href={WHATSAPP} target="_blank" rel="noreferrer">
                        <FaWhatsapp className="whatsapp-button" />
                        <span className="sr-only">Whatsapp</span>
                    </a>
                </li>
            </ul>
        </div>
    )
}

function MobileSidebar({ collapsed, collapseSidebar }: { collapsed: boolean; collapseSidebar: () => void }) {
    return (
        <Sidebar
            rtl
            defaultCollapsed
            collapsedWidth="0px"
            className="lg:hidden top-0 right-0"
            style={{
                position: 'fixed',
                zIndex: 1000,
                borderLeft: !collapsed ? '1px solid #999' : 'none',
                borderBottom: !collapsed ? '1px solid #999' : 'none',
                borderRight: 'none',
            }}
            backgroundColor="#fbfbfb"
        >
            <Menu onClick={collapseSidebar}>
                {PAGES.map((page, index) => (
                    <MenuItem key={index} href={page.url}>
                        {page.title}
                    </MenuItem>
                ))}

                <ul className="social flex justify-between sm:justify-start py-5 px-5">
                    <li className="mr-3">
                        <a href={FACEBOOK_PAGE} target="_blank" rel="noreferrer">
                            <FaFacebookF />
                            <span className="sr-only">Facebook</span>
                        </a>
                    </li>
                    <li className="mr-3">
                        <a href={MESSENGER} target="_blank" rel="noreferrer">
                            <FaFacebookMessenger />
                            <span className="sr-only">Messenger</span>
                        </a>
                    </li>
                    <li className="mr-3">
                        <a href={WHATSAPP} target="_blank" rel="noreferrer">
                            <FaWhatsapp />
                            <span className="sr-only">Whatsapp</span>
                        </a>
                    </li>
                    <li className="mr-3">
                        <a href={CELLPHONE} target="_blank" rel="noreferrer">
                            <FaPhone />
                            <span className="sr-only">Phone</span>
                        </a>
                    </li>
                    <li className="mr-3">
                        <a href={EMAIL} target="_blank" rel="noreferrer">
                            <FaAt />
                            <span className="sr-only">Email</span>
                        </a>
                    </li>
                </ul>
            </Menu>
        </Sidebar>
    )
}
