import { PureComponent } from 'react'
import { Toaster } from 'react-hot-toast'
import { Landing } from './Landing/Landing'
import { ProSidebarProvider } from 'react-pro-sidebar'

type IProps = {}

type IState = {}

export class App extends PureComponent<IProps, IState> {
    render() {
        return (
            <>
                <ProSidebarProvider>
                    <Landing />
                </ProSidebarProvider>
                {this.renderToaster()}
            </>
        )
    }

    private renderToaster() {
        return (
            <Toaster
                toastOptions={{
                    position: 'bottom-center',
                    style: {
                        borderRadius: '10px',
                        background: '#111827',
                        color: '#fff',
                    },
                }}
            />
        )
    }
}
