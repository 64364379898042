import { PureComponent } from 'react'

export class ServicesArea extends PureComponent {
    render() {
        return (
            <section className="services_area py-120" id="services">
                <div className="container">
                    <div className="row justify-center">
                        <div className="w-full lg:w-1/2">
                            <div className="section_title text-center pb-6">
                                <h5 className="sub_title">שירותים</h5>
                                <h4 className="main_title">תהליך עבודה</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-center">
                        <div className="w-full sm:w-10/12 md:w-6/12 lg:w-4/12">
                            <div className="single_services text-center mt-8 mx-3">
                                <div className="services_icon">
                                    <svg className="service_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                                        <path d="M41 0H11a1 1 0 0 0-1 1v3H7a1 1 0 0 0-1 1v42a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1v-3h3a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zm-5 46H8V6h20v7a1 1 0 0 0 1 1h7zM30 7.41 34.59 12H30zM40 42h-2c0-31 .14-29.28-.29-29.71C29.08 3.66 29.61 4 29.06 4H12V2h28z" />
                                        <path d="M32 22H12a1 1 0 0 0 0 2h20a1 1 0 0 0 0-2zM32 17H12a1 1 0 0 0 0 2h20a1 1 0 0 0 0-2zM32 27H12a1 1 0 0 0 0 2h20a1 1 0 0 0 0-2zM32 32H12a1 1 0 0 0 0 2h20a1 1 0 0 0 0-2zM22 37H12a1 1 0 0 0 0 2h10a1 1 0 0 0 0-2z" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="94" height="92" viewBox="0 0 94 92">
                                        <g>
                                            <path
                                                className="services_shape"
                                                id="Polygon_12"
                                                data-name="Polygon 12"
                                                d="M42.212,2.315a11,11,0,0,1,9.576,0l28.138,13.6a11,11,0,0,1,5.938,7.465L92.83,54.018A11,11,0,0,1,90.717,63.3L71.22,87.842A11,11,0,0,1,62.607,92H31.393a11,11,0,0,1-8.613-4.158L3.283,63.3A11,11,0,0,1,1.17,54.018L8.136,23.383a11,11,0,0,1,5.938-7.465Z"
                                            />
                                        </g>
                                    </svg>
                                </div>
                                <div className="services_content mt-5">
                                    <h3 className="services_title text-black font-semibold text-xl md:text-3xl">הגשת טופס DS160</h3>
                                    <p className="mt-4">
                                        סיוע מקצועי בהגשת הטופס ובכל השאלות הקשורות לו. כמו כן נעזור לכם להגיע לפרטים להגשת הטופס ולהכנת המסמכים
                                        הנדרשים.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="w-full sm:w-10/12 md:w-6/12 lg:w-4/12">
                            <div className="single_services text-center mt-8 mx-3">
                                <div className="services_icon">
                                    <svg className="service_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                                        <g data-name="26-pay per click">
                                            <path d="M32.714 16H17a1 1 0 0 1-1-1 6.868 6.868 0 0 1 7.286-7H28v2h-4.714c-3.8 0-4.869 2.538-5.169 4h14.6A5.155 5.155 0 0 0 38 9h2a7.156 7.156 0 0 1-7.286 7z" />
                                            <path d="M3.288 15A3.4 3.4 0 0 1 1 14.127a2.964 2.964 0 0 1 .077-4.5.939.939 0 0 1 .1-.076L15.45.164A1.009 1.009 0 0 1 16 0h21a3 3 0 0 1 3 3v3h-2V3a1 1 0 0 0-1-1H16.3L2.333 11.182a.963.963 0 0 0 .012 1.467 1.467 1.467 0 0 0 1.7.146l10.437-6.35a1 1 0 0 1 1-.025l5 2.7-.95 1.76-4.5-2.428L5.08 14.5a3.449 3.449 0 0 1-1.792.5z" />
                                            <path d="M47 13h-9v-2h8V3h-7V1h8a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM16 48a12 12 0 1 1 12-12 12.013 12.013 0 0 1-12 12zm0-22a10 10 0 1 0 10 10 10.011 10.011 0 0 0-10-10z" />
                                            <path d="M17 37h-2a3 3 0 0 1 0-6h2a3 3 0 0 1 3 3h-2a1 1 0 0 0-1-1h-2a1 1 0 0 0 0 2h2z" />
                                            <path d="M17 41h-2a3 3 0 0 1-3-3h2a1 1 0 0 0 1 1h2a1 1 0 0 0 0-2h-2v-2h2a3 3 0 0 1 0 6zM15 30h2v2h-2z" />
                                            <path d="M15 40h2v2h-2zM37 34a7 7 0 1 1 7-7 7.008 7.008 0 0 1-7 7zm0-12a5 5 0 1 0 5 5 5.006 5.006 0 0 0-5-5zM15 18h2v4h-2zM6 21h2v5H6zM6 17h2v2H6zM24 19h2v7h-2z" />
                                        </g>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="94" height="92" viewBox="0 0 94 92">
                                        <path
                                            className="services_shape"
                                            id="Polygon_12"
                                            data-name="Polygon 12"
                                            d="M42.212,2.315a11,11,0,0,1,9.576,0l28.138,13.6a11,11,0,0,1,5.938,7.465L92.83,54.018A11,11,0,0,1,90.717,63.3L71.22,87.842A11,11,0,0,1,62.607,92H31.393a11,11,0,0,1-8.613-4.158L3.283,63.3A11,11,0,0,1,1.17,54.018L8.136,23.383a11,11,0,0,1,5.938-7.465Z"
                                        />
                                    </svg>
                                </div>
                                <div className="services_content mt-5">
                                    <h3 className="services_title text-black font-semibold text-xl md:text-3xl">תשלום אגרה</h3>
                                    <p className="mt-4">נפתח עבורכם משתמש באתר השגרירות ונעזור לכם להגיע לפרטים הנדרשים לתשלום האגרה ולהגשת הטופס.</p>
                                </div>
                            </div>
                        </div>
                        <div className="w-full sm:w-10/12 md:w-6/12 lg:w-4/12">
                            <div className="single_services text-center mt-8 mx-3">
                                <div className="services_icon">
                                    <svg className="service_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                                        <g data-name="Search Calendar">
                                            <path
                                                d="M58 5h-1V4a4 4 0 0 0-8 0v1h-6V4a4 4 0 0 0-8 0v1h-6V4a4 4 0 0 0-8 0v1h-6V4a4 4 0 0 0-8 0v1H6a6.006 6.006 0 0 0-6 6v38a6.006 6.006 0 0 0 6 6h18a1 1 0 0 0 0-2H6a4 4 0 0 1-4-4V17h60v32a4 4 0 0 1-4 4H40a1 1 0 0 0 0 2h18a6.006 6.006 0 0 0 6-6V11a6.006 6.006 0 0 0-6-6zm-7-1a2 2 0 0 1 4 0v5a2 2 0 0 1-4 0zM37 4a2 2 0 0 1 4 0v5a2 2 0 0 1-4 0zM23 4a2 2 0 0 1 4 0v5a2 2 0 0 1-4 0zM9 4a2 2 0 0 1 4 0v5a2 2 0 0 1-4 0zM2 15v-4a4 4 0 0 1 4-4h1v2a4 4 0 0 0 8 0V7h6v2a4 4 0 0 0 8 0V7h6v2a4 4 0 0 0 8 0V7h6v2a4 4 0 0 0 8 0V7h1a4 4 0 0 1 4 4v4z"
                                                style={{ fill: '#202023' }}
                                            />
                                            <path
                                                d="M50 44h-4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1zm-1 4h-2v-2h2zM58 44h-4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1zm-1 4h-2v-2h2zM18 44h-4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1zm-1 4h-2v-2h2zM10 44H6a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1zm-1 4H7v-2h2zM51 37a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1zm-2 3h-2v-2h2zM59 37a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1zm-2 3h-2v-2h2zM19 37a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1zm-2 3h-2v-2h2zM11 37a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1zm-2 3H7v-2h2zM51 29a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1zm-2 3h-2v-2h2zM59 29a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1zm-2 3h-2v-2h2zM19 29a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1zm-2 3h-2v-2h2zM11 29a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1zm-2 3H7v-2h2zM59 25a1 1 0 0 0-1-1H6a1 1 0 0 0 0 2h52a1 1 0 0 0 1-1zM6 22h4a1 1 0 0 0 0-2H6a1 1 0 0 0 0 2zM18 22a1 1 0 0 0 0-2h-4a1 1 0 0 0 0 2zM26 22a1 1 0 0 0 0-2h-4a1 1 0 0 0 0 2zM34 22a1 1 0 0 0 0-2h-4a1 1 0 0 0 0 2zM42 22a1 1 0 0 0 0-2h-4a1 1 0 0 0 0 2zM50 22a1 1 0 0 0 0-2h-4a1 1 0 0 0 0 2zM58 22a1 1 0 0 0 0-2h-4a1 1 0 0 0 0 2zM43 39a11 11 0 1 0-16 9.786V59a5 5 0 0 0 10 0V48.786A11 11 0 0 0 43 39zm-20 0a9 9 0 1 1 9 9 9.01 9.01 0 0 1-9-9zm12 10.584V59a3 3 0 0 1-6 0v-9.416A12.191 12.191 0 0 0 32 50a12.191 12.191 0 0 0 3-.416z"
                                                style={{ fill: '#202023' }}
                                            />
                                            <path
                                                d="M32 47a8 8 0 1 0-8-8 8.009 8.009 0 0 0 8 8zm0-14a6 6 0 1 1-6 6 6.006 6.006 0 0 1 6-6z"
                                                style={{ fill: '#202023' }}
                                            />
                                            <path
                                                d="M29 39a3 3 0 0 1 3-3 1 1 0 0 0 0-2 5.006 5.006 0 0 0-5 5 1 1 0 0 0 2 0zM22 28a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1h1a1 1 0 0 0 0-2zM42 32a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1h-2a1 1 0 0 0 0 2h1v1a1 1 0 0 0 1 1zM22 50h2a1 1 0 0 0 0-2h-1v-1a1 1 0 0 0-2 0v2a1 1 0 0 0 1 1zM41 47v1h-1a1 1 0 0 0 0 2h2a1 1 0 0 0 1-1v-2a1 1 0 0 0-2 0z"
                                                style={{ fill: '#202023' }}
                                            />
                                        </g>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="94" height="92" viewBox="0 0 94 92">
                                        <path
                                            className="services_shape"
                                            id="Polygon_12"
                                            data-name="Polygon 12"
                                            d="M42.212,2.315a11,11,0,0,1,9.576,0l28.138,13.6a11,11,0,0,1,5.938,7.465L92.83,54.018A11,11,0,0,1,90.717,63.3L71.22,87.842A11,11,0,0,1,62.607,92H31.393a11,11,0,0,1-8.613-4.158L3.283,63.3A11,11,0,0,1,1.17,54.018L8.136,23.383a11,11,0,0,1,5.938-7.465Z"
                                        />
                                    </svg>
                                </div>
                                <div className="services_content mt-5">
                                    <h3 className="services_title text-black font-semibold text-xl md:text-3xl">הקדמת תור</h3>
                                    <p className="mt-4">
                                        תורים יכולים להיות מאוד רחוקים ולכן נותנים לכם אפשרות להקדים את התור שלכם ולסיים את התהליך בקלות ובמהירות.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
