import { Component } from 'react'
import { FaStar } from 'react-icons/fa'
import { JsonCache } from '../Util/JsonCache'
import Parse from 'parse'
import { format, parseISO } from 'date-fns'
import HeLocale from 'date-fns/locale/he'

type IFeedback = {
    image?: string
    name: string
    date: string
    review: string
    stars: number
}

type IProps = {}

type IState = {
    feedbacks?: IFeedback[]
}

export class WorkArea extends Component<IProps, IState> {
    readonly state: IState = this.getInitialState()

    private getInitialState(): IState {
        return {
            feedbacks: undefined,
        }
    }

    async componentDidMount() {
        try {
            const feedbackCache = new JsonCache<IFeedback[]>('feedback')
            const feedbacks = feedbackCache.getData()
            if (feedbacks) {
                this.setState({ feedbacks })
            } else {
                const feedbackQuery = new Parse.Query('Feedback')
                feedbackQuery.descending('date')
                const feedback = await feedbackQuery.find()
                const feedbackData = feedback.map(f => ({
                    image: f.get('image'),
                    name: f.get('name'),
                    date: format(parseISO(f.get('date')), 'd בLLLL, yyyy', { locale: HeLocale }),
                    review: f.get('review'),
                    stars: f.get('stars'),
                }))
                console.log('feedbackData', feedbackData)
                feedbackCache.setData(feedbackData)
                this.setState({ feedbacks: feedbackData })
            }
        } catch (err) {}
    }

    render() {
        return (
            <section id="work" className="work_area pt-120 pb-120">
                <div className="container">
                    <div className="row justify-center">
                        <div className="w-ull lg:w-1/2">
                            <div className="section_title text-center pb-6">
                                <h5 className="sub_title">תיק עבודות</h5>
                                <h4 className="main_title">מה אומרים עלינו</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="work_wrapper relative">
                        <div className="row work_active">{this.renderCustomerReviews()}</div>
                    </div>
                </div>
            </section>
        )
    }

    private renderCustomerReviews() {
        const { feedbacks } = this.state
        if (!feedbacks) {
            return null
        }
        return feedbacks.map(this.renderCustomerReview)
    }

    private renderCustomerReview(review: IFeedback, index: number) {
        return (
            <div key={index} className="w-full lg:w-3/12">
                <div className="single_item mx-auto">
                    <div className="single_work mx-3 px-5 py-5">
                        <div className="flex">
                            {review.image && (
                                <div className="image">
                                    <img
                                        src={review.image}
                                        className="review-profile-pic"
                                        alt="profile"
                                        style={{ borderRadius: '50%', width: 50, height: 50 }}
                                    />
                                </div>
                            )}
                            <div className="mr-3">
                                <div className="font-bold text-xl">{review.name}</div>
                                <div className="small">{review.date}</div>
                            </div>
                        </div>
                        <div className="work_content">
                            <p>{review.review}</p>
                            <div className="flex mt-5">
                                {Array.from({ length: review.stars }).map((_, index) => (
                                    <FaStar key={index} color="#f19f00" />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
