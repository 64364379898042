import { PureComponent } from 'react'

export class AboutArea extends PureComponent {
    render() {
        return (
            <section id="why" className="about_area bg-gray pt-120 relative">
                <div className="about_image flex items-center justify-center">
                    <div className="image lg:pr-13">
                        <img src="assets/images/us3-heart.png" alt="about" />
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-end">
                        <div className="w-full lg:w-1/2">
                            <div className="about_content mx-4 pt-11 lg:pt-15 lg:pb-15">
                                <div className="section_title pb-9">
                                    <h5 className="sub_title">למה לך לבחור בנו?</h5>
                                    <h4 className="main_title">המטרה שלך היא ההישג שלנו</h4>
                                </div>
                                <p>
                                    כאן תוכלו למצוא את כל מה שצריך לדעת על הגשת ויזה אמריקאית. כל הפרטים המפורטים והמדויקים על כל הסוגים של ויזות
                                    אמריקאיות והצעות ויזות אמריקאיות מהשנים האחרונות. כל הפרטים המפורטים והמדויקים
                                </p>
                                <ul className="about_list pt-3">
                                    <li className="flex mt-5">
                                        <div className="about_check">
                                            <i className="lni lni-checkmark-circle"></i>
                                        </div>
                                        <div className="about_list_content pl-5 pr-2">
                                            <p>אנחנו מספקים עזרה והדרכה מקצועית בכל שלבי הגשת הפנייה לויזה אמריקאית</p>
                                        </div>
                                    </li>
                                    <li className="flex mt-5">
                                        <div className="about_check">
                                            <i className="lni lni-checkmark-circle"></i>
                                        </div>
                                        <div className="about_list_content pl-5 pr-2">
                                            <p>יחס אישי ומקצועי עם הספקים המובילים בתחום</p>
                                        </div>
                                    </li>
                                    <li className="flex mt-5">
                                        <div className="about_check">
                                            <i className="lni lni-checkmark-circle"></i>
                                        </div>
                                        <div className="about_list_content pl-5 pr-2">
                                            <p>מאות לקוחות מרוצים שקבלו את הויזה האמריקאית שלהם בזמן ובצורה מקצועית ומהירה</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
