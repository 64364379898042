import { PureComponent } from 'react'
import { AboutArea } from './AboutArea'
// import { BlogArea } from './BlogArea'
import { ContactArea } from './ContactArea'
import { FooterArea } from './FooterArea'
import { HeaderArea } from './HeaderArea'
// import { PricingArea } from './PricingArea'
import { ServicesArea } from './ServicesArea'
// import { ServicesArea2 } from './ServicesArea2'
// import { TeamArea } from './TeamArea'
import { WorkArea } from './WorkArea'

type IProps = {}

export class Landing extends PureComponent<IProps> {
    render() {
        return (
            <div>
                {this.renderPreloader()}

                <HeaderArea />
                <ServicesArea />
                <AboutArea />
                {/* <ServicesArea2 /> */}
                <WorkArea />
                {/* <PricingArea /> */}
                {/* <TeamArea /> */}
                {/* <BlogArea /> */}

                {/* <ContactArea /> */}

                <FooterArea />

                {this.renderScrollTop()}
                {this.renderScripts()}
            </div>
        )
    }

    private renderPreloader() {
        return (
            <div className="preloader">
                <div className="loader">
                    <div className="ytp-spinner">
                        <div className="ytp-spinner-container">
                            <div className="ytp-spinner-rotator">
                                <div className="ytp-spinner-left">
                                    <div className="ytp-spinner-circle"></div>
                                </div>
                                <div className="ytp-spinner-right">
                                    <div className="ytp-spinner-circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    private renderScrollTop() {
        return (
            <a href="#" className="scroll-top">
                <i className="lni lni-chevron-up"></i>
                <span className="sr-only">Scroll to top</span>
            </a>
        )
    }

    private renderScripts() {
        return (
            <>
                <script src="assets/js/tiny-slider.js"></script>
                <script src="assets/js/wow.min.js"></script>
                <script src="assets/js/main.js"></script>
            </>
        )
    }
}
