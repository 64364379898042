import React from 'react'
import ReactDOM from 'react-dom/client'

import './index.css'
import './Assets/css/animate.css'
// import './Assets/css/tiny-slider.css'
import './Assets/fonts/lineicons/font-css/LineIcons.css'
import './Assets/css/tailwindcss.css'

import { App } from './App'
import reportWebVitals from './reportWebVitals'
import { Back4AppInit } from './Back4App'

Back4AppInit()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
