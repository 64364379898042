import { PureComponent } from 'react'
import { FaPhone } from 'react-icons/fa'
import { CELLPHONE } from '../Config'
import { Nav } from './Nav'

export class HeaderArea extends PureComponent {
    render() {
        return (
            <section className="header_area">
                <div className="navbar-area bg-white">
                    <div className="container relative">
                        <div className="row items-center">
                            <div className="w-full">
                                <Nav />
                            </div>
                        </div>
                    </div>
                </div>

                <div id="home" className="header_hero bg-gray relative z-10 overflow-hidden lg:flex items-center">
                    <div className="hero_shape shape_1">
                        <img src="assets/images/shape/shape-1.png" alt="shape" />
                    </div>
                    <div className="hero_shape shape_2">
                        <img src="assets/images/shape/shape-2.png" alt="shape" />
                    </div>
                    <div className="hero_shape shape_3">
                        <img src="assets/images/shape/shape-3.png" alt="shape" />
                    </div>
                    <div className="hero_shape shape_4">
                        <img src="assets/images/shape/shape-4.png" alt="shape" />
                    </div>
                    <div className="hero_shape shape_6">
                        <img src="assets/images/shape/shape-1.png" alt="shape" />
                    </div>
                    <div className="hero_shape shape_7">
                        <img src="assets/images/shape/shape-4.png" alt="shape" />
                    </div>
                    <div className="hero_shape shape_8">
                        <img src="assets/images/shape/shape-3.png" alt="shape" />
                    </div>
                    <div className="hero_shape shape_9">
                        <img src="assets/images/shape/shape-2.png" alt="shape" />
                    </div>
                    <div className="hero_shape shape_10">
                        <img src="assets/images/shape/shape-4.png" alt="shape" />
                    </div>
                    <div className="hero_shape shape_11">
                        <img src="assets/images/shape/shape-1.png" alt="shape" />
                    </div>
                    <div className="hero_shape shape_12">
                        <img src="assets/images/shape/shape-2.png" alt="shape" />
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="w-full lg:w-1/2">
                                <div className="header_hero_content pt-150 lg:pt-0">
                                    <h1 className="hero_title text-2xl sm:text-4xl md:text-5xl lg:text-4xl xl:text-5xl font-extrabold">
                                        {/* קצת <span className="text-theme-color">עלינו</span> */}
                                        הוצאת ויזה <span className="text-theme-color">לארה"ב</span> בצורה פשוטה ומהירה
                                    </h1>
                                    <p className="mt-8 lg:ml-8">
                                        Visa4Usa היא החברה המובילה בתחום הגשת מסמכים והקדמת תורים לראיונות לצורך הוצאת ויזה אמריקאית מהירה ובטוחה.
                                        אנחנו ב-Visa4Usa נסייע לכם בכל הדרוש על מנת שאתם תגיעו לטיסה המיוחלת לארצות הברית. נתקעתם בתהליך הגשת מסמך?
                                        נתקעתם בתהליך הקדמת תור לראיון? אנחנו פה כדי לעזור לכם.
                                    </p>
                                    <div className="hero_btn mt-10">
                                        <a className="main-btn" href={CELLPHONE}>
                                            <FaPhone className="inline-block text-xl" /> התקשרו אלינו
                                        </a>
                                    </div>
                                    {/* <div className="hero_btn mt-10">
                                        <a className="main-btn" href="#contact">
                                            להשארת פרטים
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header_shape hidden lg:block"></div>

                    <div className="header_image flex items-center">
                        <div className="image 2xl:pl-25">
                            <img src="assets/images/us-flag.png" alt="Header" />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
