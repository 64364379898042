import { PureComponent } from 'react'
import { FaFacebookMessenger, FaWhatsapp, FaPhone, FaAt, FaFacebookF } from 'react-icons/fa'
import { CELLPHONE, EMAIL, FACEBOOK_PAGE, MESSENGER, WHATSAPP } from '../Config'
import LogoLightSvg from '../Assets/images/logo_light.svg'

export class FooterArea extends PureComponent {
    render() {
        return (
            <footer id="footer" className="footer_area bg-black relative z-10">
                <div className="container">
                    <div className="footer_widget pt-18 pb-120">
                        <div className="row justify-center">
                            <div className="w-full md:w-1/2 lg:w-3/12">
                                <div className="footer_about mt-13 mx-3">
                                    <div className="footer_logo">
                                        <a href="#">
                                            <img src="logo_dark.png" alt="" width={150} />
                                        </a>
                                    </div>
                                    <div className="footer_content mt-8">
                                        <p className="text-white">אנחנו עוזרים לכם מהשלב הראשון של מילוי הטפסים ועד לקבלה</p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full md:w-1/2 lg:w-5/12">
                                <div className="footer_link_wrapper flex flex-wrap mx-3">
                                    <div className="footer_link w-1/2 md:pl-13 mt-13">
                                        <h2 className="footer_title text-xl font-semibold text-white">קישורים מהירים</h2>
                                        <ul className="link pt-4">
                                            <li>
                                                <a href="#" className="text-white mt-4 hover:text-theme-color">
                                                    חברה
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" className="text-white mt-4 hover:text-theme-color">
                                                    מדיניות פרטיות
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" className="text-white mt-4 hover:text-theme-color">
                                                    אודות
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="footer_link w-1/2 md:pl-13 mt-13">
                                        <h2 className="footer_title text-xl font-semibold text-white">משאבים</h2>
                                        <ul className="link pt-4">
                                            <li>
                                                <a href="#" className="text-white mt-4 hover:text-theme-color">
                                                    תמיכה
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" className="text-white mt-4 hover:text-theme-color">
                                                    צור קשר
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" className="text-white mt-4 hover:text-theme-color">
                                                    תנאים
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer_copyright pt-3 pb-6 border-t-2 border-solid border-white border-opacity-10 sm:flex justify-between">
                        <div className="footer_social pt-4 mx-3 text-center">
                            <ul className="social flex justify-center sm:justify-start">
                                <li className="mr-3">
                                    <a href={FACEBOOK_PAGE} target="_blank" rel="noreferrer">
                                        <FaFacebookF />
                                        <span className="sr-only">Facebook</span>
                                    </a>
                                </li>
                                <li className="mr-3">
                                    <a href={MESSENGER} target="_blank" rel="noreferrer">
                                        <FaFacebookMessenger />
                                        <span className="sr-only">Messenger</span>
                                    </a>
                                </li>
                                <li className="mr-3">
                                    <a href={WHATSAPP} target="_blank" rel="noreferrer">
                                        <FaWhatsapp />
                                        <span className="sr-only">Whatsapp</span>
                                    </a>
                                </li>
                                <li className="mr-3">
                                    <a href={CELLPHONE} target="_blank" rel="noreferrer">
                                        <FaPhone />
                                        <span className="sr-only">Phone</span>
                                    </a>
                                </li>
                                <li className="mr-3">
                                    <a href={EMAIL} target="_blank" rel="noreferrer">
                                        <FaAt />
                                        <span className="sr-only">Email</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
