/**
 * A simple cache for JSON objects using the LocalStorage API.
 * @param {string} name cache name.
 * @param {number} [ttl=0] The time to live for the cache in milliseconds.
 */
export class JsonCache<T> {
    private name: string
    private ttl: number

    constructor(name: string, ttl = 1_000 * 60 * 60) {
        this.name = name
        this.ttl = ttl
    }

    /**
     * Returns the cached object or null if the cache is empty or expired.
     * @returns {any} The cached object or null.
     */
    public getData(): T | null {
        const dataStr = localStorage.getItem(this.name)
        if (dataStr) {
            const data = JSON.parse(dataStr)
            if (data.expires && data.expires > Date.now()) {
                return data.json
            }
        }
        return null
    }

    /**
     * Caches the given object.
     * @param {any} json The object to cache.
     * @param {number} [ttl] The time to live for the cache in milliseconds.
     * If not specified, the default ttl is used.
     * @returns {boolean} true if the object was cached, false otherwise.
     */
    public setData(json: T, ttl?: number): boolean {
        if (ttl === undefined) {
            ttl = this.ttl
        }
        try {
            const data = {
                json,
                expires: ttl ? Date.now() + ttl : 0,
            }
            localStorage.setItem(this.name, JSON.stringify(data))
            return true
        } catch (e) {
            return false
        }
    }
}
